import React from "react";

import { COLOR_INSPECCION_POSITIVA, COLOR_INSPECCION_NEGATIVA, COLOR_BASE, INSPECTION_TEXT, SPRAYED_TEXT, 
COLOR_BASE_ROCIADO, FECHA_SEGUNDO_ROCIADO_PEDREGAL } from "../resources";

//Cuando la microred no tienen 
const NoApp = (houses, visited, user) => {
    
    //Numero de rociado
    let numRociado = 0;

    //Preguntar si es adicional o no
    if (visited.LAT && visited.LAT!== null && visited.LAT !== "NA") {
      AdditionalNoapp(houses, visited);
    }
    
    houses.some((house) => {
        if (house.UNICODE === visited.UNICODE) {
            //Esto se pone por que van a haber alguna inspecciones pasadas que luego se les hizo
            //visita como C, LV, R por que antes si era posible hacerlo, ahora en el futuro esto
            //ya no se podra realizar.
            if (house.STATUS_INSPECCION !== "inspeccion" || visited.STATUS_INSPECCION === "inspeccion") {
                //Almacenar status
                house.STATUS_INSPECCION = visited.STATUS_INSPECCION;
                //Alamcenar fecha
                house.FECHA = visited.FECHA;
                //Cambiar popup
                house.inspectionText =  <div>Visita Insp: <b>{visited.STATUS_INSPECCION}</b>:<b>{visited.FECHA}</b></div>;
                
                //Cambiar color
                if ( house.STATUS_INSPECCION=== "inspeccion") {
                    //Pintar del color respectivo la inspeccion
                    if ("1" === visited.INTRA_CHIRIS || "1" === visited.PERI_CHIRIS || true === visited.INTRA_CHIRIS || true === visited.PERI_CHIRIS) {
                        house.color = COLOR_INSPECCION_POSITIVA;
                    } else {
                        house.color = COLOR_INSPECCION_NEGATIVA;
                    }  
                }
            }   

            //Numero de rociado
            if (user.USU_MICRORED !== "20" && house.FECHA < FECHA_SEGUNDO_ROCIADO_PEDREGAL) {
                //Numero de rociado
                numRociado = 1;
            } else {
                numRociado = 2;
            }

            return true;
        } else {
            return false;
        }
    });

    return [houses, numRociado];
}

//Adicional en vectorpoint
const AdditionalNoapp = (houses, additional) => {
    
    additional.LATITUDE = additional.LAT;
    additional.LONGITUDE = additional.LNG;
    additional.additional = 1;
    additional.color = COLOR_BASE;
    additional.initialColor = COLOR_BASE;
    additional.inspectionText = INSPECTION_TEXT;
    additional.rociadoText = SPRAYED_TEXT;
    additional.sprayed = COLOR_BASE_ROCIADO;

    houses.push(additional);

    return houses;
}

export default NoApp;