import React from 'react';

import { COLOR_BASE_ROCIADO, COLOR_ROCIADO_POSITIVO, COLOR_ROCIADO_NEGATIVO, COLOR_SEGUNDO_ROCIADO_POSITIVO, 
         COLOR_SEGUNDO_ROCIADO_NEGATIVO, FECHA_SEGUNDO_ROCIADO_PEDREGAL } from "../resources";

//Funcion para rociados
const RociadoPoint = (houses, visita, numMicrored) => {
    //Numero de rociado
    let numRociado = 0;

    houses.some(house => {
        if (visita.UNICODE === house.UNICODE) {
            
            //Especificamente para pedregal (20) y empezo el segundo rociado el dia 2021/10/05
            if (numMicrored !== "20" && house.FECHA < FECHA_SEGUNDO_ROCIADO_PEDREGAL ) {
                numRociado = 1;
                //Popup
                house.rociadoText = <div>
                                    Visita Rociado: <b>{visita.ROC_TRATAMIENTO_RESIDUAL}</b>:<b>{visita.ROC_FECHA}</b>
                                    <br/>
                                    Visita Rociado Foco: {visita.ROC_COLINDANTE_INSP}
                                </div>;

                if( visita.ROC_TRATAMIENTO_RESIDUAL === "T" && ((visita.ROC_INTRA_CANT_CAPTURADOS !== "NA" && visita.ROC_INTRA_CANT_CAPTURADOS !== "0") || (visita.ROC_PERI_CANT_CAPTURADOS !== "NA" && visita.ROC_PERI_CANT_CAPTURADOS !== "0"))  ) {
                    house.sprayed = COLOR_ROCIADO_POSITIVO;
                } else if( visita.ROC_TRATAMIENTO_RESIDUAL === "T" && ((visita.ROC_INTRA_CANT_CAPTURADOS === "NA" || visita.ROC_INTRA_CANT_CAPTURADOS === "0") && (visita.ROC_PERI_CANT_CAPTURADOS === "NA" || visita.ROC_PERI_CANT_CAPTURADOS === "0"))){
                    house.sprayed = COLOR_ROCIADO_NEGATIVO;
                }    
            } else {
                numRociado = 2;

                if ( house.sprayed === COLOR_BASE_ROCIADO ) {
                    //Popup
                    house.rociadoText = <div>
                                    Visita Rociado: <b>{visita.ROC_TRATAMIENTO_RESIDUAL}</b>:<b>{visita.ROC_FECHA}</b>
                                    <br/>
                                    Visita Rociado Foco: {visita.ROC_COLINDANTE_INSP}
                                </div>;
                } else {
                    
                    //let aux = ReactDomServer.renderToStaticMarkup(house.rociadoText);
                    //Popup
                    house.rociadoText = [house.rociadoText, <div>
                                    Visita Rociado: <b>{visita.ROC_TRATAMIENTO_RESIDUAL}</b>:<b>{visita.ROC_FECHA}</b>
                                    <br/>
                                    Visita Rociado Foco: {visita.ROC_COLINDANTE_INSP}
                                </div>];                
                }

                if(visita.ROC_TRATAMIENTO_RESIDUAL === "T" && ((visita.ROC_INTRA_CANT_CAPTURADOS !== "NA" && visita.ROC_INTRA_CANT_CAPTURADOS !== "0") || (visita.ROC_PERI_CANT_CAPTURADOS !== "NA" && visita.ROC_PERI_CANT_CAPTURADOS !== "0"))  ) {
                    house.sprayed = COLOR_SEGUNDO_ROCIADO_POSITIVO;
                } else if( visita.ROC_TRATAMIENTO_RESIDUAL === "T" && ((visita.ROC_INTRA_CANT_CAPTURADOS === "NA" || visita.ROC_INTRA_CANT_CAPTURADOS === "0") && (visita.ROC_PERI_CANT_CAPTURADOS === "NA" || visita.ROC_PERI_CANT_CAPTURADOS === "0"))){
                    house.sprayed = COLOR_SEGUNDO_ROCIADO_NEGATIVO;
                }
            }
            
            return true;
        }else {
            return false;
        }
    });

    return [houses, numRociado];
}

export default RociadoPoint;