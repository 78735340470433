import React from "react";

import { COLOR_PRINCIPAL_BP, COLOR_SECUNDARIO_BP, COLOR_OFF_BP, COLOR_INSPECCION_POSITIVA, 
         COLOR_INSPECCION_NEGATIVA, P_BP_BLUE, P_BP_OTHER, INSPECTION_TEXT, FECHA_SEGUNDO_ROCIADO_PEDREGAL } from "../resources";

//Puntos azules
//#Si una vivienda del grupo es inspeccionada se apaga todo el grupo y se pone amarillo, se mantiene el boton para que puedan ingresar informacion si desean
//#  -Si es una vivienda azul:
//      - El flujo continua como siempre, pasa al siguiente de mayor prioridad
//      - Si una vivienda fue visitada sin que sea punto azul, cuando lleva nuevamente el punto azul pasa a la siguiente
//#  -Si una vivienda no es azul sigue normal solo cambio su status
//#Si es otro que no sea inspeccion no pasa nada y solo cambia su status
//#NOTA: Si por si a caso se visita una vivienda que no es azul y en el mismo dia se visita una vivienda azul y luego le toca ponerse azul a esa vivienda que 
//       primero se reviso y no era azul si esta en el mismo dia la asignacion de vivienda azul se pasa al siguiente.
const BluePoint = (houses, visited, user) => {
    
    //Numero de rociado
    let numRociado = 0;

    //Preguntar si es adicional o no
    if (visited.LAT && visited.LAT!== null && visited.LAT !== "NA") {
      AdditionalBP(houses, visited);
    }
    
    houses.some((house, index) => {
        if (house.UNICODE === visited.UNICODE) {
            /*Esto se pone por que van a haber alguna inspecciones pasadas que luego se les hizo
            //visita como C, LV, R por que antes si era posible hacerlo, ahora en el futuro esto
            //ya no se podra realizar.*/
            if (house.STATUS_INSPECCION !== "inspeccion" || visited.STATUS_INSPECCION === "inspeccion") {
                //Encontrar el indice inferior
                let indInferior = (index-house.order)+1;
                //Puntero que caminara por todo el grupo
                let pIndex = index+1;//Apunta a la siguiente vivienda
                //Booleano utilizado para recorrer el while
                let bool = true;
                //Almacenar status
                house.STATUS_INSPECCION = visited.STATUS_INSPECCION;
                //Alamcenar fecha
                house.FECHA = visited.FECHA;
                //Cambiar popup
                house.inspectionText =  <div>Visita Insp: <b>{visited.STATUS_INSPECCION}</b>:<b>{visited.FECHA}</b></div>;
                
                //Cambiar color
                if ( house.STATUS_INSPECCION === "inspeccion") {
                    //Puntaje
                    if( visited.USER_NAME === user.USU_CUENTA.toUpperCase()){
                        switch ( house.color ) {
                            case COLOR_PRINCIPAL_BP:
                                user.SCORE += P_BP_BLUE;
                                break;
                            default:
                                user.SCORE += P_BP_OTHER;
                                break;
                        }
                    }
                    //Aqui revisa si es una vivienda adicionada, solo las vivienda NO ADICIONADAS ingresan
                    if (house.group_num) {//Esos dos IF se poner separados para que se entienda mejor
                        //Verificar que no haya sido amarillo anteriormente
                        if (house.color !== COLOR_OFF_BP) {
                            //Pintar todos del color apagado
                            while ( bool ){
                                if (pIndex === index) {
                                    bool = false;
                                } else if (houses[pIndex] && houses[pIndex].group_num === house.group_num){
                                    houses[pIndex].color = COLOR_OFF_BP;
                                    pIndex = pIndex+1;
                                } else {
                                    pIndex = indInferior;
                                }
                            }   
                        }
                    }
                    //Pintar del color respectivo la inspeccion
                    if ("1" === visited.INTRA_CHIRIS || "1" === visited.PERI_CHIRIS || true === visited.INTRA_CHIRIS || true === visited.PERI_CHIRIS) {
                        house.color = COLOR_INSPECCION_POSITIVA;
                    } else {
                        house.color = COLOR_INSPECCION_NEGATIVA;
                    }  
                //Vivienda azul pero C,R, entrevista excepto VOLVER(V)
                } else if (house.color === COLOR_PRINCIPAL_BP && house.STATUS_INSPECCION !== "V") {
                    //Cambiando a gris vivienda
                    house.color = COLOR_SECUNDARIO_BP
                    //Aqui revisa si es una vivienda adicionada, solo las vivienda NO ADICIONADAS ingresan
                    if (house.group_num) {
                        //Empiezo a recorrer el grupo
                        while (bool) {
                            if (pIndex === index) {
                                bool = false;
                            } else if (houses[pIndex] && houses[pIndex].group_num === house.group_num) {
                                //La diferencia entre la fecha importa si es en el mismo dia
                                if ( houses[pIndex].FECHA !== house.FECHA){
                                    houses[pIndex].color = COLOR_PRINCIPAL_BP;
                                    bool = false;
                                } else 
                                    pIndex++;
                            } else {
                                pIndex = indInferior;
                            }
                        }
                    }
                }
            }
            
            //Numero de rociado
            if (user.USU_MICRORED !== "20" && house.FECHA < FECHA_SEGUNDO_ROCIADO_PEDREGAL) {
                //Numero de rociado
                numRociado = 1;
            } else {
                numRociado = 2;
            }

            return true;
        } else {
            return false;
        }
    });

    return [houses, numRociado];
}

//Adicional en vectorpoint
const AdditionalBP = (houses, additional) => {
  
  //Agregar la vivienda al final del su grupo de cuadra
  houses.some((element, index)=> {
    if (additional.UNICODE_BASE === element.UNICODE) {
      //Almacenando variables de grupo y orden de la vivienda base
      additional.group_num = element.group_num;
      additional.order = parseInt(element.order)+1;

      let bool = true;
      let newIndex = index+1;
      while (bool) {
        if (houses[newIndex].group_num === additional.group_num) {
          //Aumentamos "order" en uno
          houses[newIndex].order = parseInt(houses[newIndex].order)+1
          newIndex++;
        } else {
          additional.LATITUDE = additional.LAT;
          additional.LONGITUDE = additional.LNG;
          //Editando los campos necesarios cuando la adicionada viene desde la base de datos
          if (additional.PERSONAS_PREDIO) {
            additional.additional = 1;
            additional.color = additional.RISK_COLOR;
            additional.initialColor = additional.INITIAL_COLOR;
            additional.inspectionText = INSPECTION_TEXT;
          }

          /*Agregando la vivienda adicional en el lugar indicado
          Se esta poniendo la vivienda despues de la vivienda base y todas las demas recorren un orden*/
          houses.splice(index+1, 0, additional);
          bool = false;
        }
      }

      return true;
    } else {
      return false;
    }
  });
}

export default BluePoint;